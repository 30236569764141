import React, { useMemo, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { debounce } from "lodash";

const SearchBar = ({
  data,
  setFilteredAds,
  fetchMoreAds,
  hasMore,
  searchTerm,
  setSearchTerm,
  setIsSearching,
}) => {
  // Debounced fetch initialized once (no changing dependencies)
  const debouncedFetch = useMemo(
    () => debounce((term) => fetchMoreAds(term), 300),
    [fetchMoreAds]
  );

  useEffect(() => {
    return () => {
      debouncedFetch.cancel(); // Cleanup debounce on unmount
    };
  }, [debouncedFetch]);

  const handleSearchChange = async (event) => {
    const term = event.target.value;

    if (term === "") {
      setSearchTerm("");
      setIsSearching(false); // Turn off search mode
      setFilteredAds(data); // Reset filtered ads to all available ads

      // Continue lazy loading if more ads exist
      if (hasMore) {
        debouncedFetch(""); // Fetch more ads for lazy loading
      }
      return;
    }

    const lowerTerm = term.toLowerCase();
    setSearchTerm(lowerTerm); // Update search term
    setIsSearching(true); // Enable search mode

    // Trigger fetch if more ads might match the search
    if (hasMore) {
      debouncedFetch(lowerTerm);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { xs: "90%", sm: "70%", md: "40%", lg: "30%" },
        margin: "auto",
        mt: 4,
        borderRadius: "8px",
      }}
    >
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearchChange}
      />
    </Box>
  );
};

export default SearchBar;
