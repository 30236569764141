import React, { useState } from "react";
import ConfirmDialog from "./ConfirmDialog";
import BusinessCard from "./BusinessCard";

const AdLink = ({ ad, baseUrl }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [pendingAdUrl, setPendingAdUrl] = useState(null);

  const handleAdClick = (event, url) => {
    // check if the ad is restricted
    if (ad.restricted) {
      event.preventDefault(); // Prevent default navigation
      setPendingAdUrl(url); // Store the URL
      setDialogOpen(true); // Show confirmation dialog
    } else {
      if (url) {
        window.open(url, "_blank"); // Open the ad URL in a new tab
      }
    }
  };

  const handleDialogClose = (confirmed) => {
    setDialogOpen(false);

    if (confirmed && pendingAdUrl) {
      window.open(pendingAdUrl, "_blank"); // Open the ad URL in a new tab
    }
  };

  return (
    <>
      <div onClick={(event) => handleAdClick(event, ad.url)}>
        <BusinessCard ad={ad} />
      </div>
      <ConfirmDialog open={isDialogOpen} onClose={handleDialogClose} />
    </>
  );
};

export default AdLink;
