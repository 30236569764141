import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import DynamicTitle from "./DynamicTitle";
import StatsContainer from "./StatsContainer";
import Lottie from "react-lottie-player";
import SearchBar from "./SearchBar";
import AdLink from "./AdLink";

// import App.css
import "../App.css";
import CircularProgress from "@mui/material/CircularProgress";

function BusinessCards({ city, baseUrl, state }) {
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const lastVisibleIdRef = useRef(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const searchTermRef = useRef("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  // Update the ref whenever searchTerm changes
  useEffect(() => {
    searchTermRef.current = searchTerm;
  }, [searchTerm]);

  const renderedAds = useMemo(() => {
    return filteredAds.map((ad) => {
      return (
        <Grid key={ad.name} xs={12} sm={6} md={4}>
          <AdLink ad={ad} baseUrl={baseUrl} />
        </Grid>
      );
    });
  }, [filteredAds, baseUrl]);

  const preloadImages = (adsToPreload) => {
    return Promise.all(
      adsToPreload.map((ad) => {
        // if ad.imageUrl is not defined then return an empty promise
        if (!ad.imageUrl) return Promise.resolve();

        return new Promise((resolve) => {
          const img = new Image();
          img.src = ad.imageUrl;
          img.onload = resolve;
          img.onerror = resolve;
        });
      })
    );
  };

  const fetchAds = useCallback(
    async (searchTerm = searchTermRef.current, isRecursive = false) => {
      // Prevent fetching if already loading or no more ads are available
      if (!baseUrl || loading || (!isRecursive && searchLoading) || !hasMore)
        return;

      // Show loading indicators
      if (!isRecursive) {
        setLoading(true); // Initial fetch
      } else {
        setSearchLoading(true); // Recursive fetch
      }

      setError(null);

      try {
        const query = lastVisibleIdRef.current
          ? `?last_visible_id=${encodeURIComponent(lastVisibleIdRef.current)}`
          : "";

        console.log("fetching ads", `${baseUrl}/ads${query}`);

        const response = await axios.get(`${baseUrl}/ads${query}`);
        await preloadImages(response.data.ads);

        setAds((prevAds) => {
          const updatedAds = [...prevAds, ...response.data.ads];

          // Filter matches based on search term
          const filtered = updatedAds.filter((item) => {
            const itemDescription = item.description
              ? item.description.toLowerCase()
              : "";
            const itemName = item.name ? item.name.toLowerCase() : "";
            return (
              itemDescription.includes(searchTerm.toLowerCase()) ||
              itemName.includes(searchTerm.toLowerCase())
            );
          });

          // Merge matches with existing filtered ads
          setFilteredAds((prevFilteredAds) => {
            const combined = [...prevFilteredAds, ...filtered];

            // Deduplicate by 'name'
            return combined.filter(
              (ad, index, self) =>
                index === self.findIndex((a) => a.name === ad.name)
            );
          });

          return updatedAds; // Return updated ads for future searches
        });

        lastVisibleIdRef.current = response.data.last_visible_id;
        const hasMoreAds = response.data.ads.length > 0;
        setHasMore(hasMoreAds);

        // Recursive fetch until no more ads are available
        if (isSearching && hasMoreAds) {
          await fetchAds(searchTerm, true); // Continue recursive fetch
        }
      } catch (err) {
        console.error("Failed to fetch ads:", err);
        setError("Failed to load ads. Please try again later.");
      } finally {
        if (!isRecursive) {
          setLoading(false); // Reset loading for initial fetch
        }
        setSearchLoading(false); // Reset recursive loading
        setInitialLoading(false);
      }
    },
    [baseUrl, hasMore, loading, searchLoading, isSearching]
  );

  useEffect(() => {
    if (baseUrl && initialLoading) {
      fetchAds(); // Fetch ads on first load
    }
  }, [baseUrl, fetchAds, initialLoading]);

  useEffect(() => {
    // Re-filter ads whenever the search term changes
    const lowerTerm = searchTerm.toLowerCase();

    setFilteredAds(
      ads.filter((item) => {
        const itemDescription = item.description
          ? item.description.toLowerCase()
          : "";
        const itemName = item.name ? item.name.toLowerCase() : "";
        return (
          itemDescription.includes(lowerTerm) || itemName.includes(lowerTerm)
        );
      })
    );
  }, [searchTerm, ads]); // Dependencies: searchTerm and ads

  useEffect(() => {
    let throttleTimeout = null;

    const throttledHandleScroll = () => {
      if (throttleTimeout || isSearching) return; // Skip lazy loading during search

      throttleTimeout = setTimeout(() => {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        const scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight =
          document.documentElement.clientHeight || document.body.clientHeight;

        // Fetch more ads when near the bottom
        if (scrollTop + clientHeight >= scrollHeight * 0.8) {
          fetchAds(); // Lazy load
        }

        throttleTimeout = null;
      }, 200);
    };

    window.addEventListener("scroll", throttledHandleScroll);

    return () => {
      clearTimeout(throttleTimeout);
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [fetchAds, loading, hasMore, isSearching]);

  if (initialLoading) {
    // Render Lottie animation during the initial fetch
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#ffffff",
        }}
      >
        <Lottie
          loop
          animationData={require("../assets/loading.json")}
          play
          style={{ width: 300, height: 300 }}
        />
      </Box>
    );
  }

  if (error) {
    return <p style={{ textAlign: "center", color: "red" }}>{error}</p>;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 0,
        backgroundColor: "#ffffff", // Ensures no gray background
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <DynamicTitle city={city} state={state} />
      <StatsContainer baseUrl={baseUrl} />
      <SearchBar
        data={ads}
        setFilteredAds={setFilteredAds}
        fetchMoreAds={fetchAds}
        hasMore={hasMore}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setIsSearching={setIsSearching}
        fetchAds={fetchAds}
      />
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="start"
        sx={{
          padding: "20px", // Add padding to the Grid
        }}
      >
        {renderedAds}
      </Grid>
      {/* Small Loading Indicator */}
      {(loading || searchLoading) && hasMore && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
          }}
        >
          <CircularProgress color="inherit" size={20} />
        </Box>
      )}
    </Box>
  );
}

export default BusinessCards;
