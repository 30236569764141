import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ city, state, domain }) => {
    const description = `${city} ${state} Hub is your local community bulletin board, connecting businesses with customers. Discover local businesses, their services, and see live traffic stats for your advertisements.`;
    const keywords = `${city} ${state}, local businesses, advertising, community bulletin board, business directory, ${city} ${state} Hub, small business ads`;
    const author = `${city} ${state} Hub`;
    const ogDescription = `Connect with local businesses in ${city}, ${state}. Discover services, advertise your business, and track traffic stats for your ads.`;
    const title = `${city} ${state} Hub - Your Local Business Directory`;
    const url = `https://www.${domain}`;

    return (
        <Helmet>
            {/* Meta Tags */}
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta name="author" content={author} />
            <meta name="robots" content="index, follow" />
            <meta name="theme-color" content="#007bff" />

            {/* Open Graph Metadata */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={ogDescription} />
            <meta property="og:image" content="/static/images/hub-social.jpg" />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />

            {/* Twitter Card Metadata */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={ogDescription} />
            <meta name="twitter:image" content="/static/images/hub-social.jpg" />

            {/* Canonical URL */}
            <link rel="canonical" href={url} />

            {/* Favicon */}
            <link rel="icon" href="https://trentonmohub.com/favicon.ico" type="image/x-icon" />

            {/* Stylesheet */}
            {/* <link rel="stylesheet" href="/static/css/styles.css" /> */}

            {/* JSON-LD Structured Data */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "LocalBusiness",
                    name: `${city} ${state} Hub`,
                    url,
                    description: `${city} ${state} Hub is a community bulletin board that connects local businesses with customers in ${city}, ${state}.`,
                    address: {
                        "@type": "PostalAddress",
                        addressLocality: city,
                        addressRegion: state,
                        addressCountry: "US",
                    },
                    image: "https://www.trentonmohub.com/static/images/hub-social.jpg",
                    telephone: "+1-660-555-1234",
                })}
            </script>

            <title>{title}</title>
        </Helmet>
    );
};

export default Head;
